import React, {Component} from "react";
import ContentHeader from "../commons/board-header.component";
import {connect} from "react-redux";
import withContext from "../../common/withContext";
import {Link} from "react-router-dom";
import Table from "../commons/table.component";
import * as constants from "../../common/constants";
import {isMobile} from "react-device-detect";
import {deleteCard, findCards} from "../../actions/card";
import ConfirmBox from "../commons/confirm-box.component";
import notification from "../../common/notification";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async";
import {ROLE, toDateEndOnly, toDateOnly} from "../../common/constants";
import {findUsers} from "../../actions/user";


class BoardCards extends Component {

    constructor(props) {
        super(props);
        this.findCards = this.findCards.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.handleDateStartChange = this.handleDateStartChange.bind(this);
        this.handleDateEndChange = this.handleDateEndChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.onHandleAthleteChange = this.onHandleAthleteChange.bind(this);
        this.loadAthletesOptions = this.loadAthletesOptions.bind(this);
        this.state = {
            pageIndex: 0,
            isConfirmOpen: false,
            cardId: null
        };
    }

    findCards(pageRequest) {
        this.props.findCards(
            pageRequest.pageIndex,
            pageRequest.pageSize,
            'createdAt,DESC',
            toDateOnly(this.state.dateStart),
            toDateEndOnly(this.state.dateEnd),
            this.state.athlete?.value);
        this.setState({
            pageIndex: pageRequest.pageIndex
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        this.findCards({
            pageIndex: 0,
            pageSize: 0
        })
    }

    handleDateStartChange(date) {
        this.setState({
            dateStart: date
        })
    }

    handleDateEndChange(date) {
        this.setState({
            dateEnd: date
        })
    }

    loadAthletesOptions(inputValue, callback) {
        this.props
            .findAthletes(0, 10, inputValue)
            .then(result => {
                const athletes = result.data.content;
                let athletesOptions = athletes?.map(item => this.asSelectItem(item));
                callback(athletesOptions)
            })
    }

    onHandleAthleteChange(event) {
        this.setState({
            athlete: event
        });
    }

    asSelectItem(item) {
        return {
            value: item.id,
            label: item.name + (item.surname ? ' ' + item.surname : '')
        };
    }

    clearFilters() {
        this.setState({
            dateEnd: null,
            dateStart: null,
            athlete: null,
        })
    }

    handleDelete(row) {
        console.log(row.original);
        this.setState({
            isConfirmOpen: true,
            cardId: row.original.id
        })
    }

    handleClose() {
        this.setState({
            isConfirmOpen: false,
            cardId: null
        })
    }

    handleConfirm() {
        this.props
            .deleteCard(this.state.cardId)
            .then(result => {
                notification.info(this.t('message.delete-success'));
                this.findCards({
                    pageIndex: this.state.pageIndex,
                    pageSize: this.state.pageSize
                })
            })
            .catch(error => {
                notification.error(this.t('message.delete-failed'));
            })
            .finally(() => {
                this.setState({
                    isConfirmOpen: false,
                    cardId: null
                })
            })
    }

    handleCancel() {
        this.setState({
            isConfirmOpen: false,
            cardId: null
        })
    }

    t(key) {
        return this.props.translate(`card.board.${key}`);
    }

    render() {
        const cards = this.props.cards.content ? this.props.cards.content : [];
        const totalPages = this.props.cards.totalPages ? this.props.cards.totalPages : 1;
        const totalElements = this.props.cards.totalElements ? this.props.cards.totalElements : 0;
        const tablePageIndex = this.state.pageIndex >= totalPages ? totalPages - 1 : this.state.pageIndex;

        const colAthlete = {
            Header: this.t('table-header.athlete'),
            accessor: (props) => (props.athlete.name + ' ' + props.athlete.surname)
        };

        const colSize = {
            Header: this.t('table-header.size'),
            Cell: ({row}) => (
                <div style={{textAlign: "center"}}>
                    {row.original.size}
                </div>)
        }

        const colLeft = {
            Header: this.t('table-header.left'),
            Cell: ({row}) => (
                <div style={{textAlign: "center"}}>
                    {row.original.size - row.original.usedCount}
                </div>)
        }

        const colCreatedAt = {
            Header: this.t('table-header.createdat'),
            Cell: ({row}) => (
                <div style={{ textAlign: "center" }}>
                    {constants.formatDateTime(row.original.createdAt)}
                </div>)
        }

        const colActions = {
            Header: this.t('table-header.actions'),
            Cell: ({row}) => (
                <div style={{ textAlign: "center" }}>
                    <button onClick={() => this.handleDelete(row)}
                            className="btn btn-sm btn-warning ml-lg-2">
                        <i className="fas fa-trash mr-md-2 mr-lg-2"/>
                        <span className="d-none d-md-inline d-lg-inline">
                            {isMobile ? '' : this.t('btn.delete')}
                        </span>
                    </button>
                </div>
            )
        }

        const tableColumns = isMobile ?
            [colAthlete, colSize, colLeft, colActions] :
            [colAthlete, colSize, colLeft, colCreatedAt, colActions];

        const {athlete, isConfirmOpen} = this.state;

        return (
            <React.Fragment>
                <ContentHeader title={this.t('title')}
                               icon="fa-address-card"
                               breadcrumbs={[
                                   {label: this.t('title'), href: '/cards', active: true},
                               ]}/>
                <section className="content pl-1 pr-1 pl-md-2 pl-lg-2 pl-xl-2 pr-0 pr-md-2 pr-lg-2 pr-xl-2">
                    <div className="container-fluid pl-1 pr-1 pl-md-2 pl-lg-2 pl-xl-2 pr-0 pr-md-2 pr-lg-2 pr-xl-2">
                        <div className="row mb-4 mt-2">
                            <div className="col-sm-12 col-md-5 col-lg-3 col-xl-3">
                                <Link to={`/card/create`}
                                      className="btn btn-primary btn-block mt-2">
                                    <i className={`mr-lg-2 fas fa-plus mr-2`}/>
                                    {this.t('btn.add')}
                                </Link>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-3 col-xl-3">
                                <a data-toggle="collapse"
                                   className="btn btn-primary btn-block mt-2"
                                   href="#cardsFilters"
                                   role="button"
                                   aria-expanded="false"
                                   aria-controls="lessonsFilters">
                                    <i className="fas fa-chevron-right mr-2"/>
                                    {this.t('btn.filter')}
                                </a>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <div className="collapse" id="cardsFilters">
                                    <div className="card card-body">
                                        <form onSubmit={this.handleSubmit}
                                              autoComplete="off">

                                            <div className="form-row">
                                                <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                    <label htmlFor="startDate">
                                                        {this.t('form.input.date-start')}
                                                    </label>
                                                    <DatePicker id="startDate"
                                                                name="startDate"
                                                                className="form-control"
                                                                selected={this.state.dateStart}
                                                                onChange={this.handleDateStartChange}
                                                                placeholderText={this.t('form.input.date-start-placeholder')}
                                                                dateFormat="dd/MM/yy"
                                                                autoComplete="off"
                                                                popperModifiers={[{
                                                                    name: 'arrow',
                                                                    options: {padding: 24},
                                                                },]}/>
                                                </div>

                                                <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                    <label htmlFor="endDate">
                                                        {this.t('form.input.date-end')}
                                                    </label>
                                                    <DatePicker id="endDate"
                                                                name="endDate"
                                                                className="form-control"
                                                                selected={this.state.dateEnd}
                                                                onChange={this.handleDateEndChange}
                                                                placeholderText={this.t('form.input.date-end-placeholder')}
                                                                dateFormat="dd/MM/yy"
                                                                autoComplete="off"
                                                                popperModifiers={[{
                                                                    name: 'arrow',
                                                                    options: {padding: 24},
                                                                },]}/>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                    <label htmlFor="athlete">
                                                        {this.t('form.input.athlete')}
                                                    </label>
                                                    <AsyncSelect id="athlete"
                                                                 name="athlete"
                                                                 searchable="true"
                                                                 placeholder={this.t('form.input.athlete-placeholder')}
                                                                 cacheOptions
                                                                 value={athlete}
                                                                 loadOptions={this.loadAthletesOptions}
                                                                 onChange={this.onHandleAthleteChange}/>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-4 col-12">
                                                    <button type="submit"
                                                            className="btn btn-primary btn-block">
                                                        {this.t('form.btn.find')}
                                                    </button>
                                                </div>
                                                <div className="form-group col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-4 col-12">
                                                    <button type="submit"
                                                            className="btn btn-primary btn-block"
                                                            onClick={this.clearFilters}>
                                                        {this.t('form.btn.find-clear')}
                                                    </button>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 col-12">
                                {this.t('table-description')}
                                <Table columns={tableColumns}
                                       data={cards}
                                       totalPages={totalPages}
                                       totalElements={totalElements}
                                       tablePageIndex={tablePageIndex}
                                       fetchData={this.findCards}
                                       tablePageSize={constants.TABLE_DEFAULT_PAGE_SIZE}
                                />
                            </div>
                        </div>
                    </div>
                    <ConfirmBox
                        options={{
                            icon:"fa-trash",
                            text: this.t('dialog.delete-confirmation'),
                            confirm: this.t('dialog.yes'),
                            cancel: this.t('dialog.no'),
                            btn: true
                        }}
                        isOpen={isConfirmOpen}
                        onClose={this.handleClose}
                        onConfirm={this.handleConfirm}
                        onCancel={this.handleCancel}
                    />
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    translate: ownProps.t,
    cards: state.card.cards,
})

const mapDispatchToProps = (dispatch) => ({
    findAthletes: (page, size, searchText) => dispatch(findUsers(page, size, searchText, ROLE.ATHLETE)),
    findCards: (page, size, sorting, dateStart, dateEnd, athleteId) => dispatch(findCards(page, size, sorting, dateStart, dateEnd, athleteId)),
    deleteCard: (id) => dispatch(deleteCard(id)),
})

export default withContext(connect(mapStateToProps, mapDispatchToProps)(BoardCards))
