import React, {Component} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {connect} from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Login from "./components/login/login.component";
import BoardHorses from "./components/horses/board-horses.component";
import BoardLessons from "./components/lessons/board-lessons.component";

import {clearMessage} from "./actions/message";

import {history} from './helpers/history';

import EventBus from "./common/EventBus";
import Navbar from "./components/commons/navbar.component";
import Sidebar from "./components/commons/sidebar.component";
import './custom.scss';
import BoardReport from "./components/report/board-report.component";
import HorseFormPage from "./components/horses/horse-form-page.component";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BoardInstructors from "./components/users/board-instructors.component";
import BoardAthletes from "./components/users/board-athletes.component";
import UserFormPage from "./components/users/user-form-page.component";
import {ROLE} from "./common/constants";
import LessonFormPage from "./components/lessons/lesson-form-page.component";
import withTranslationContext from "./common/withTranslationContext";
import BoardCards from "./components/cards/board-cards.component";
import CardFormPage from "./components/cards/card-form-page.component";


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
        };

        history.listen((location) => {
            props.dispatch(clearMessage()); // clear message when changing location
        });
    }

    componentDidMount() {
        const user = this.props.user;

        if (user) {
            this.setState({
                user: user,
            });
        }

        EventBus.on("login", (user) => {
            this.setState({
                user: user,
            });
        });


        EventBus.on("logout", (user) => {
            this.setState({
                user: undefined
            })
        });
    }


    render() {

        const {user} = this.state;
        const {t} = this.props;

        return (
            <Router>
                {user ?
                    <React.Fragment>
                        <Navbar/>
                        <Sidebar/>
                        <div className="content-wrapper">
                            <Routes history={history}>

                                <Route exact path="/" element={<BoardLessons/>}/>
                                <Route exact path="/reports" element={<BoardReport/>}/>

                                <Route exact path="/horses" element={<BoardHorses/>}/>
                                <Route exact path="/horse/:id/edit" element={
                                    <HorseFormPage history={history}
                                                   parentPageLabel={t('horse.board.title')}
                                                   successRedirect={"/horses"} />
                                }/>
                                <Route exact path="/horse/create" element={
                                    <HorseFormPage history={history}
                                                   parentPageLabel={t('horse.board.title')}
                                                   successRedirect={"/horses"} />
                                }/>

                                <Route exact path="/instructors" element={<BoardInstructors/>}/>
                                <Route exact path="/instructor/:id/edit" element={
                                    <UserFormPage role={ROLE.INSTRUCTOR}
                                                  history={history}
                                                  parentPageLabel={t('instructor.board.title')}
                                                  successRedirect={"/instructors"} />
                                }/>
                                <Route exact path="/instructor/create" element={
                                    <UserFormPage role={ROLE.INSTRUCTOR}
                                                  history={history}
                                                  parentPageLabel={t('instructor.board.title')}
                                                  successRedirect={"/instructors"} />
                                }/>
                                <Route exact path="/athletes" element={<BoardAthletes/>}/>
                                <Route exact path="/athlete/:id/edit" element={
                                    <UserFormPage role={ROLE.ATHLETE}
                                                  history={history}
                                                  parentPageLabel={t('athlete.board.title')}
                                                  successRedirect={"/athletes"}/>
                                }/>
                                <Route exact path="/athlete/create" element={
                                    <UserFormPage role={ROLE.ATHLETE}
                                                  history={history}
                                                  parentPageLabel={t('athlete.board.title')}
                                                  successRedirect={"/athletes"}/>
                                }/>

                                <Route exact path="/lessons" element={<BoardLessons history={history}/>}/>
                                <Route exact path="/lesson/:id/edit" element={
                                    <LessonFormPage history={history}
                                                    parentPageLabel={t('lesson.board.title')}
                                                    successRedirect={"/lessons"}/>
                                }/>
                                <Route exact path="/lesson/create" element={
                                    <LessonFormPage history={history}
                                                    parentPageLabel={t('lesson.board.title')}
                                                    successRedirect={"/lessons"}/>
                                }/>

                                <Route exact path="/cards" element={<BoardCards/>}/>
                                <Route exact path="/card/create" element={
                                    <CardFormPage history={history}
                                                  parentPageLabel={t('card.board.title')}
                                                  successRedirect={"/cards"}/>}/>
                            </Routes>
                            <ToastContainer theme="colored"/>
                        </div>
                    </React.Fragment>
                    :
                    <div className="content">
                        <div className="row">
                            <div className="col-xl-4 col-md-4 col-sm-2 col-1">
                            </div>
                            <div className="col-xl-4 col-md-4 col-sm-8 col-10">
                                <Routes>
                                    <Route exact path="/" element={<Login/>}/>
                                    <Route exact path="/login" element={<Login/>}/>
                                    <Route exact path="/*" element={<Login/>}/>
                                </Routes>
                            </div>
                            <div className="col-xl-4 col-md-4 col-sm-2 col-1">
                            </div>
                        </div>
                    </div>
                }
            </Router>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    t: ownProps.t,
    user: state.auth.user,
})

export default withTranslationContext(connect(mapStateToProps)(App))
