import React from 'react';
import {useTranslation} from "react-i18next";

const withTranslationContext = WrappedComponent => props => {
    const {t} = useTranslation('language');
    return (
        <WrappedComponent
            {...props}
            t={t}
        />
    );
};

export default withTranslationContext;
