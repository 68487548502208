import instance from '../services/api';

export const findUsers = (page, size, searchText, role) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.get('user', {
            params: {
                page: page,
                size: size,
                role: role,
                sort: 'updatedAt,DESC',
                searchText: searchText
            }
        }).then((response) => {
            dispatch({type: 'FIND_USERS', value: response.data})
            resolve(response)
        }, (error) => {
            reject(error)
        })
    });
}

export const findUserById = (id, role) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.get(`user/${id}`)
            .then((response) => {
                dispatch({type: 'FIND_USER', value: response.data})
                resolve(response)
            }, (error) => {
                reject(error)
            })
    });
}

export const addUser = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post('user', data)
            .then((response) => {
                dispatch({type: 'ADD_USER', value: data})
                resolve(response)
            }, (error) => {
                reject(error)
            })
    });
}

export const updateUser = (data, id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.put(`user/${id}`, data)
            .then((res) => {
                dispatch({type: 'UPDATE_USER', value: data, id: id})
                resolve(res)
            }, (err) => {
                reject(err)
            })
    });
}

export const deleteUser = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.delete(`user/${id}`)
            .then((response) => {
                dispatch({type: 'DELETE_USER', value: response.data, id: id})
                resolve(response)
            }, (err) => {
                reject(err)
            })
    });
}
