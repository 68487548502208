import React, {Component} from 'react'
import {connect} from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";

import setHours from "date-fns/setHours";
import {findHorses} from "../../actions/horse";
import withContext from "../../common/withContext";
import ContentHeader from "../commons/board-header.component";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import {findUsers} from "../../actions/user";
import {ROLE, nowAsEvenHour} from "../../common/constants";
import {addLesson, findLessonById, updateLesson} from "../../actions/lesson";
import notification from "../../common/notification";

class LessonFormPage extends Component {

    constructor(props) {
        super(props);
        this.onHandleDateChange = this.onHandleDateChange.bind(this);
        this.loadHorsesOptions = this.loadHorsesOptions.bind(this);
        this.loadAthletesOptions = this.loadAthletesOptions.bind(this);
        this.onHandleHorseChange = this.onHandleHorseChange.bind(this);
        this.onHandleAthleteChange = this.onHandleAthleteChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {};
    }

    isEdit() {
        return this.props.id
    }

    componentDidMount() {
        if (this.isEdit()) {
            const lessonId = this.props.id;
            this.props
                .findLessonById(lessonId)
                .then(result => {
                    const lesson = result.data
                    this.setState({
                        lesson: {
                            date: new Date(lesson.date),
                            horse: this.asSelectItem(lesson.horse),
                            athlete: this.asSelectItem(lesson.athlete),
                            instructor: this.asSelectItem(lesson.instructor)
                        }
                    })
                })
        } else {
            const {user} = this.props.user;
            this.setState({
                lesson: {
                    date: nowAsEvenHour(),
                    instructor: this.asSelectItem(user)
                }
            });
        }

        this.findHorses({
            pageIndex: 0,
            pageSize: 10
        }).then(response => {
            this.setState({
                horses: response.data.content
            })
        })
    }

    findHorses(pageRequest) {
        return this.props.findHorses(
            pageRequest.pageIndex,
            pageRequest.pageSize,
            this.state.searchTerm);
    }

    loadAthletesOptions(inputValue, callback) {
        this.props.findUsers(0, 10, inputValue).then(result => {
            const athletes = result.data.content;
            let athletesOptions = athletes?.map(item => this.asSelectItem(item));
            callback(athletesOptions)
        })
    }

    loadHorsesOptions(inputValue, callback) {
        this.props.findHorses(0, 10, inputValue).then(result => {
            const horses = result.data.content;
            const horsesOptions = horses?.map(item => this.asSelectItem(item));
            callback(horsesOptions)
        })
    }

    onHandleAthleteChange(event) {
        this.setState({
            lesson: {
                ...this.state.lesson,
                athlete: event
            }
        });
    }

    onHandleHorseChange(event) {
        this.setState({
            lesson: {
                ...this.state.lesson,
                horse: event
            }
        });
    }

    onHandleDateChange(date) {
        this.setState({
            lesson: {
                ...this.state.lesson,
                date: date
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        const {lesson} = this.state;

        if (this.isEdit()) {
            const lessonId = this.props.id;
            this.props
                .updateLesson({
                    instructorId: lesson.instructor.value,
                    athleteId: lesson.athlete.value,
                    horseId: lesson.horse.value,
                    dateTime: lesson.date
                }, lessonId)
                .then(() => {
                    notification.success(this.t('message.update-success'));
                    this.props.navigation(this.props.successRedirect);
                })
                .catch(error => {
                    notification.error(this.t('message.update-failed'));
                });

        } else {
            this.props
                .addLesson({
                    instructorId: lesson.instructor.value,
                    athleteId: lesson.athlete.value,
                    horseId: lesson.horse.value,
                    dateTime: lesson.date
                })
                .then(() => {
                    notification.success(this.t('message.add-success'));
                    this.props.navigation(this.props.successRedirect);
                })
                .catch(error => {
                    notification.error(this.t('message.add-failed'));
                });
        }
    }

    asSelectItem(item) {
        return {
            value: item.id,
            label: item.name + (item.surname ? ' ' + item.surname : '')
        };
    }

    t(key) {
        return this.props.translate(`lesson.form.${key}`)
    }

    render() {
        const {lesson, horses} = this.state;
        const horsesOptions = horses?.map(horse => this.asSelectItem(horse));
        const athletesOptions = []; // TODO: To fill with the most frequent athletes for current logged user.

        return (
            <React.Fragment>
                <ContentHeader title={this.isEdit() ? this.t('title-edit') : this.t('title-add')}
                               icon="fa-solid fa-calendar mr-2"
                               breadcrumbs={[
                                   {label: this.props.parentPageLabel, href: this.props.successRedirect, active: true},
                                   {label: this.isEdit() ? this.t('title-edit') : this.t('title-add'), href: null, active: true}
                               ]}/>

                <section className="content pl-1 pr-1 pl-md-2 pl-lg-2 pl-xl-2 pr-0 pr-md-2 pr-lg-2 pr-xl-2">
                    <div className="container-fluid pl-1 pr-1 pl-md-2 pl-lg-2 pl-xl-2 pr-0 pr-md-2 pr-lg-2 pr-xl-2">
                        <div className="col-lg-6 col-12">

                            <form onSubmit={this.handleSubmit}
                                  className="needs-validation"
                                  autoComplete="off">
                                <div className="form-group">
                                    <label htmlFor="athlete">
                                        {this.t('input.athlete')}
                                    </label>
                                    <AsyncSelect id="athlete"
                                                 name="athlete"
                                                 searchable="true"
                                                 placeholder={this.t('input.athlete-placeholder')}
                                                 cacheOptions
                                                 value={lesson?.athlete}
                                                 defaultOptions={athletesOptions}
                                                 aria-describedby="athleteHelp"
                                                 loadOptions={this.loadAthletesOptions}
                                                 onChange={this.onHandleAthleteChange}
                                                 required />
                                    <small id="athleteHelp"
                                           className="form-text text-muted">
                                        {this.t('input.athlete-help')}
                                    </small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="horse">
                                        {this.t('input.horse')}
                                    </label>
                                    <AsyncSelect id="horse"
                                                 name="horse"
                                                 searchable="true"
                                                 placeholder={this.t('input.horse-placeholder')}
                                                 cacheOptions
                                                 value={lesson?.horse}
                                                 defaultOptions={horsesOptions}
                                                 loadOptions={this.loadHorsesOptions}
                                                 onChange={this.onHandleHorseChange}
                                                 required />
                                    <small id="horseHelp"
                                           className="form-text text-muted">
                                        {this.t('input.horse-help')}
                                    </small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="name">
                                        {this.t('input.datetime')}
                                    </label>
                                    <DatePicker showTimeSelect
                                                selected={lesson?.date}
                                                className="form-control"
                                                placeholderText={this.t('input.datetime-placeholder')}
                                                onChange={this.onHandleDateChange}
                                                dateFormat="dd/MM/yy HH:mm"
                                                timeFormat="HH:mm"
                                                timeIntervals={60}
                                                timeCaption="Orario"
                                                minTime={setHours(new Date(), 6)}
                                                maxTime={setHours(new Date(), 22)}
                                                popperModifiers={[{ name: 'arrow', options: {padding: 24},},]}
                                                onFocus={e => e.target.blur()}
                                                required/>
                                    <small id="nameHelp"
                                           className="form-text text-muted">
                                        {this.t('input.datetime-help')}
                                    </small>
                                </div>

                                <input type="submit"
                                       className="btn btn-primary"
                                       value={this.isEdit() ? this.t('btn.edit') : this.t('btn.add')}/>
                            </form>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    id: ownProps.params.id,
    translate: ownProps.t,
    navigation: ownProps.navigation,
    user: state.auth,
    athletes: state.user.users,
    horses: state.horse.horses,
    successRedirect: ownProps.successRedirect,
    parentPageLabel: ownProps.parentPageLabel
})

const mapDispatchToProps = (dispatch) => ({
    findLessonById: (id) => dispatch(findLessonById(id)),
    addLesson: (data) => dispatch(addLesson(data)),
    updateLesson: (data, id) => dispatch(updateLesson(data, id)),
    findUsers: (page, size, searchText) => dispatch(findUsers(page, size, searchText, ROLE.ATHLETE)),
    findHorses: (page, size, name) => dispatch(findHorses(page, size, name))
})

export default withContext(connect(mapStateToProps, mapDispatchToProps)(LessonFormPage))
