import instance from '../services/api';
import { saveAs } from 'file-saver';

export const findReports = (page, size) => {
    return new Promise((resolve, reject) => {
        instance.get('report', {
            params: {
                page: page,
                size: size,
                sort: 'createdAt,DESC'
            }
        }).then((response) => {
            resolve(response)
        }, (error) => {
            reject(error)
        })
    });
}

export const createReport = (type, start, end) => {
    return new Promise((resolve, reject) => {
        instance.post(`report/create`, {
            startDate: start,
            endDate: end,
            type: type,

        }).then((response) => {
                resolve(response)
            }, (error) => {
                reject(error)
            })
    });
}

export const downloadReport = (uuid) => {
    return new Promise((resolve, reject) => {
        instance.get(`report/${uuid}`, {responseType: 'blob'}).then((response) => {
            let filename = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
            let extension = response.headers['content-disposition'].split('.')[1].split(';')[0];
            saveAs(response.data, filename + '.' + extension);
            resolve(response)
        }, (error) => {
            reject(error)
        })
    });
}
