import instance from '../services/api';

export const findHorses = (page, size, name, sorting) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.get('horse', {
            params: {
                page: page,
                size: size,
                sort: 'updatedAt,DESC',
                name: name
            }
        }).then((response) => {
            dispatch({type: 'FIND_HORSES', value: response.data})
            resolve(response)
        }, (error) => {
            reject(error)
        })
    });
}

export const findHorseById = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.get(`horse/${id}`)
            .then((response) => {
                dispatch({type: 'FIND_HORSE', value: response.data})
                resolve(response)
            }, (error) => {
                reject(error)
            })
    });
}

export const addHorse = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post('horse', data)
            .then((response) => {
                dispatch({type: 'ADD_HORSE', value: data})
                resolve(response)
            }, (error) => {
                reject(error)
            })
    });
}

export const updateHorse = (data, id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.put(`horse/${id}`, data)
            .then((res) => {
                dispatch({type: 'UPDATE_HORSE', value: data, id: id})
                resolve(res)
            }, (err) => {
                reject(err)
            })
    });
}

export const deleteHorse = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.delete(`horse/${id}`)
            .then((response) => {
                dispatch({type: 'DELETE_HORSE', value: response.data, id: id})
                resolve(response)
            }, (err) => {
                reject(err)
            })
    });
}
