import { combineReducers } from "redux";
import auth from "./auth";
import horse from "./horse";
import message from "./message";
import user from "./user";
import lesson from "./lesson";
import card from "./card";

export default combineReducers({
    auth,
    message,
    horse,
    user,
    lesson,
    card
});
