import React, { Component } from "react";
import {NavLink} from "react-router-dom";
import EventBus from "../../common/EventBus";
import {connect} from "react-redux";
import {logout} from "../../actions/auth";
import './navbar.component.scss';
import {isAdmin, isInstructor} from "../../common/constants";
import withContext from "../../common/withContext";

class Navbar extends Component {

    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);
        this.state = {
            user: undefined,
            showHorses: false,
            showLessons: false,
            showReport: false,
            showAthletes: false,
            showInstructors: false,
            showLessonCards: false
        };
    }

    componentDidMount() {
        const user = this.props.user;
        if (user) {
            const admin = isAdmin(user);
            const instructor = isInstructor(user);
            this.setState({
                user: user,
                showHorses: admin,
                showLessons: admin || instructor,
                showReport: admin,
                showAthletes: admin,
                showInstructors: admin,
                showLessonCards: admin
            });
        }

        EventBus.on("logout", () => {
            this.logOut();
        });

        EventBus.on("login", (user) => {
            const admin = isAdmin(user);
            const instructor = isInstructor(user);
            this.setState({
                user: user,
                showHorses: admin,
                showLessons: admin || instructor,
                showReport: admin,
                showAthletes: admin,
                showInstructors: admin,
                showLessonCards: admin
            });
        });
    }

    componentWillUnmount() {
        EventBus.remove("logout");
    }

    logOut() {
        this.props.dispatch(logout());
        this.setState({
            user: undefined,
            showHorses: false,
            showLessons: false,
            showReport: false,
            showAthletes: false,
            showInstructors: false,
            showLessonCards: false,
        });
    }

    t(key) {
        return this.props.translate(key);
    }

    render() {
        const {
            user,
            showHorses,
            showLessons,
            showReport,
            showAthletes,
            showInstructors,
            showLessonCards
        } = this.state;

        return (
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="!#" role="button">
                            <i className="fas fa-bars"/></a>
                    </li>
                    <span className="navbar-brand ml-lg-2">
                        <img src="./logo.png" alt="Logo" height="36"/>
                    </span>

                    {showLessons && (
                        <li className="nav-item d-none d-sm-inline-block">
                            <NavLink to={'/lessons'}  className="nav-link">
                                {this.t('lesson.board.title')}
                            </NavLink>
                        </li>
                    )}

                    {showReport && (
                        <li className="nav-item d-none d-sm-inline-block">
                            <NavLink to={'/reports'}  className="nav-link">
                                {this.t('report.board.title')}
                            </NavLink>
                        </li>
                    )}

                    {showAthletes && (
                        <li className="nav-item d-none d-sm-inline-block">
                            <NavLink to={'/athletes'}  className="nav-link">
                                {this.t('athlete.board.title')}
                            </NavLink>
                        </li>
                    )}

                    {showHorses && (
                        <li className="nav-item d-none d-sm-inline-block">
                            <NavLink to={'/horses'}  className="nav-link">
                                {this.t('horse.board.title')}
                            </NavLink>
                        </li>
                    )}

                    {showInstructors && (
                        <li className="nav-item d-none d-sm-inline-block">
                            <NavLink to={'/instructors'}  className="nav-link">
                                {this.t('instructor.board.title')}
                            </NavLink>
                        </li>
                    )}

                    {showLessonCards && (
                        <li className="nav-item d-none d-sm-inline-block">
                            <NavLink to={'/cards'}  className="nav-link">
                                {this.t('card.board.title')}
                            </NavLink>
                        </li>
                    )}
                </ul>


                {user ? (
                    <div className="navbar-nav ml-auto">
                        <li>
                            <div className="nav-link">
                                <i className="fas fa-user mr-2"/>
                                {user.name}
                            </div>
                        </li>
                        <li className="nav-item">
                            <a href="/login" className="nav-link mr-2" onClick={this.logOut}>
                                Log out
                            </a>
                        </li>
                    </div>
                ) :
                    null
                }
            </nav>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    translate: ownProps.t,
    user: state.auth.user,
})

export default withContext(connect(mapStateToProps)(Navbar))
