import instance from '../services/api';

export const findCards = (page, size, sorting, dateStart, dateEnd, athleteId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post('/lesson/card/search', {
                dateStart: dateStart,
                dateEnd: dateEnd,
                athleteId: athleteId,
            },
            {
                params: {
                    page: page,
                    size: size,
                    sort: sorting,
                }
            }).then((response) => {
            dispatch({type: 'FIND_CARDS', value: response.data})
            resolve(response)
        }, (error) => {
            reject(error)
        })
    });
}

export const findCardById = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.get(`lesson/card/${id}`)
            .then((response) => {
                dispatch({type: 'FIND_CARD', value: response.data})
                resolve(response)
            }, (error) => {
                reject(error)
            })
    });
}

export const addCard = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post('lesson/card', data)
            .then((response) => {
                dispatch({type: 'ADD_CARD', value: data})
                resolve(response)
            }, (error) => {
                reject(error)
            })
    });
}

export const deleteCard = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.delete(`lesson/card/${id}`)
            .then((response) => {
                dispatch({type: 'DELETE_CARD', value: response.data, id: id})
                resolve(response)
            }, (err) => {
                reject(err)
            })
    });
}
