import React, {Component} from 'react'
import {connect} from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {updateHorse, addHorse, findHorseById} from "../../actions/horse";
import withContext from "../../common/withContext";
import notification from "../../common/notification";
import ContentHeader from "../commons/board-header.component";
import {toDateOnly} from "../../common/constants";

class HorseFormPage extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.state = {};
    }

    componentDidMount() {
        if (this.isEdit()) {
            const horseId = this.props.id;
            this.props
                .findHorseById(horseId)
                .then(result => {
                    const horse = result.data
                    this.setState({
                        horse: {
                            name: horse.name,
                            birthdate: new Date(horse.birthdate)
                        }
                    });
                });
        }
    }

    isEdit() {
        return this.props.id
    }

    handleInputChange(event) {
        this.setState({
            horse: {
                ...this.state.horse,
                [event.target.name]: event.target.value
            }
        })
    }

    handleDateChange(date) {
        this.setState({
            horse: {
                ...this.state.horse,
                birthdate: date
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.isEdit()) {
            const horseId = this.props.id
            this.props
                .updateHorse({
                        ...this.state.horse,
                        birthdate: toDateOnly(this.state.horse.birthdate)
                    }, horseId)
                .then(() => {
                    notification.success(this.t('message.update-success'));
                    this.props.navigation(this.props.successRedirect);
                })
                .catch((error) => {
                    notification.error(this.t('message.update-failed'));
                })

        } else {
            this.props
                .addHorse({
                    ...this.state.horse,
                    birthdate: toDateOnly(this.state.horse.birthdate)
                })
                .then(() => {
                    notification.success(this.t('message.add-success'));
                    this.props.navigation(this.props.successRedirect);
                })
                .catch((error) => {
                    notification.error(this.t('message.add-failed'));
                });
        }
    }

    t(key) {
        return this.props.translate(`horse.form.${key}`);
    }

    render() {
        const {horse} = this.state;
        return (
            <React.Fragment>
                <ContentHeader title={this.isEdit() ? this.t('title-edit') : this.t('title-add')}
                               icon="fa-horse-head"
                               breadcrumbs={[
                                   { label: this.props.parentPageLabel, href: this.props.successRedirect, active: true },
                                   {label: this.isEdit() ? this.t('title-edit') : this.t('title-add'), href: null, active: true}
                               ]}/>

                <section className="content pl-1 pr-1 pl-md-2 pl-lg-2 pl-xl-2 pr-0 pr-md-2 pr-lg-2 pr-xl-2">
                    <div className="container-fluid pl-1 pr-1 pl-md-2 pl-lg-2 pl-xl-2 pr-0 pr-md-2 pr-lg-2 pr-xl-2">
                        <div className="col-lg-6 col-12">

                            <form onSubmit={this.handleSubmit}
                                  className="needs-validation"
                                  autoComplete="off">

                                <div className="form-group">
                                    <label htmlFor="name">
                                        {this.t('input.name')}
                                    </label>
                                    <input id="name"
                                           name="name"
                                           type="text"
                                           value={horse?.name}
                                           autoComplete="nope"
                                           className="form-control"
                                           placeholder={this.t('input.name-placeholder')}
                                           aria-describedby="nameHelp"
                                           onChange={this.handleInputChange}
                                           required />
                                    <small id="nameHelp"
                                           className="form-text text-muted">
                                        {this.t('input.name-help')}
                                    </small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="birthdate">
                                        {this.t('input.birthdate')}
                                    </label>
                                    <DatePicker id="birthdate"
                                                name="birthdate"
                                                className="form-control"
                                                placeholderText={this.t('input.birthdate-placeholder')}
                                                selected={horse?.birthdate}
                                                dateFormat="dd/MM/yy"
                                                popperModifiers={[{name: 'arrow', options: {padding: 24}}]}
                                                aria-describedby="birthdateHelp"
                                                onChange={this.handleDateChange}
                                                required />
                                    <small id="birthdateHelp"
                                           className="form-text text-muted">
                                        {this.t('input.birthdate-help')}
                                    </small>
                                </div>

                                <input type="submit"
                                       className="btn btn-primary"
                                       value={this.isEdit() ? this.t('btn.edit') : this.t('btn.add')}/>
                            </form>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    id: ownProps.params.id,
    translate: ownProps.t,
    navigation: ownProps.navigation,
    successRedirect: ownProps.successRedirect,
    parentPageLabel: ownProps.parentPageLabel
})

const mapDispatchToProps = (dispatch) => ({
    addHorse: (data) => dispatch(addHorse(data)),
    findHorseById: (id) => dispatch(findHorseById(id)),
    updateHorse: (data, id) => dispatch(updateHorse(data, id)),
})

export default withContext(connect(mapStateToProps, mapDispatchToProps)(HorseFormPage))
