import api from "./api";
import TokenService from "./token.service";

class AuthService {

    login(username, password) {
        return api
            .post("/auth/sign-in", {
                username,
                password
            })
            .then(response => {
                if (response.data.accessToken) {
                    TokenService.setUser(response.data);
                }

                return response.data;
            });
    }

    logout() {
        TokenService.removeUser();
    }

    register(username, email, password) {
        return api.post("/auth/sign-up", {
            username,
            email,
            password
        });
    }
}

export default new AuthService();
