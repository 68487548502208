import {format} from "date-fns";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import setSeconds from "date-fns/setSeconds";
import setMilliseconds from "date-fns/setMilliseconds";

export const TABLE_DEFAULT_PAGE_SIZE = 10;

export const ROLE = {
    INSTRUCTOR: 'ROLE_INSTRUCTOR',
    ATHLETE: 'ROLE_ATHLETE',
    ADMIN: 'ROLE_ADMIN',
}

export const REPORT_TYPE = {
    ALL: 'ALL',
    BY_HORSE: 'BY_HORSE',
    BY_ATHLETE: 'BY_ATHLETE',
    BY_INSTRUCTOR: 'BY_INSTRUCTOR'
}

export const REPORT_STATUS = {
    COMPLETED: 'COMPLETED',
    PROGRESS: 'PROGRESS',
    ERROR: 'ERROR',
}

// If the current time is for example: 09:31, then next even hour will be 10:00.
export const nowAsEvenHour = () => {
    let date = new Date();
    let minutes = date.getMinutes();
    if (minutes > 30) {
        return setHours(
            setMilliseconds(
                setSeconds(
                    setMinutes(new Date(), 0), 0), 0),
            date.getHours() + 1);
    } else {
        return setMilliseconds(
            setSeconds(
                setMinutes(new Date(), 0), 0), 0);
    }
}

export const toDateEndOnly = (date) => {
    return date ? new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59)) :
        null;
}

export const toDateOnly = (date) => {
    return date ? new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        0,
        0)) :
        null;
}

export const formatDate = (date) => {
    const parsed = new Date(date);
    return format(parsed, "dd/MM/yy");
}

export const formatDateTime = (date) => {
    const parsed = new Date(date);
    return format(parsed, "dd/MM/yy HH:mm");
}

export const isAdmin = (user) => {
    return user.roles.includes(ROLE.ADMIN);
}

export const isAthlete = (user) => {
    return user.roles.includes(ROLE.ATHLETE);
}

export const isInstructor = (user) => {
    return user.roles.includes(ROLE.INSTRUCTOR);
}

export const translationKeyBy = (role) => {
    if (ROLE.ADMIN === role) return "admin";
    if (ROLE.ATHLETE === role) return "athlete";
    if (ROLE.INSTRUCTOR === role) return "instructor";
    return null;
}

export const iconByRole = (role) => {
    if (ROLE.ATHLETE === role) return "fa-user-graduate";
    if (ROLE.INSTRUCTOR === role) return "fa-chalkboard-teacher";
    return null;
}
