import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import './sidebar.component.css';
import withContext from "../../common/withContext";
import {isAdmin, isInstructor} from "../../common/constants";
import {isMobile} from 'react-device-detect';

class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.onItemClicked = this.onItemClicked.bind(this);
        this.state = {
            user: undefined,
            showHorses: false,
            showLessons: false,
            showReport: false,
            showAthletes: false,
            showInstructors: false
        };
    }

    componentDidMount() {
        const user = this.props.user;
        if (user) {
            const admin = isAdmin(user);
            const instructor = isInstructor(user);
            this.setState({
                user: user,
                showHorses: admin,
                showLessons: admin || instructor,
                showReport: admin,
                showAthletes: admin,
                showInstructors: admin,
                showLessonCards: admin
            });
        }
    }

    t(key) {
        return this.props.translate(key);
    }

    onItemClicked() {
        if (isMobile) {
            document.body.classList.add('sidebar-closed', 'sidebar-collapse');
            document.body.classList.remove('sidebar-open');
        }
    }

    render() {
        const {
            user,
            showHorses,
            showLessons,
            showReport,
            showAthletes,
            showInstructors,
            showLessonCards
        } = this.state;

        const username = user?.name;
        return (
            <aside className="main-sidebar elevation-4 sidebar-background">
                <span className="brand-link">
                    <img src="./logo.png" alt="Logo" className="brand-image"/>
                    <span className="brand-text font-weight-light">
                        <strong>
                            {this.t('sidebar.title')}
                        </strong>
                    </span>
                </span>
                <div className="sidebar">
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div className="image">
                            <i className="fas fa-2x fa-user"/>
                        </div>
                        <div className="info">
                            <span className="d-block">{username}</span>
                        </div>
                    </div>
                    <nav className="menu-panel">
                        <ul className="nav nav-pills nav-sidebar flex-column"
                            data-widget="treeview"
                            role="menu"
                            data-accordion="false">

                            {showLessons && (
                                <li className="nav-item">
                                    <NavLink to={'/lessons'} className="nav-link" onClick={this.onItemClicked}>
                                        <i className="nav-icon fas fa-solid fa-calendar"/>
                                        <p className="ml-2">
                                            {this.t('lesson.board.title')}
                                        </p>
                                    </NavLink>
                                </li>
                            )}

                            {showReport && (
                                <li className="nav-item">
                                    <NavLink to={'/reports'} className="nav-link" onClick={this.onItemClicked}>
                                        <i className="nav-icon fas fa-solid fa-file"/>
                                        <p className="ml-2">
                                            {this.t('report.board.title')}
                                        </p>
                                    </NavLink>
                                </li>
                            )}

                            {showAthletes && (
                                <li className="nav-item">
                                    <NavLink to={'/athletes'} className="nav-link" onClick={this.onItemClicked}>
                                        <i className="nav-icon fas fa-solid fa-user-graduate"/>
                                        <p className="ml-2">
                                            {this.t('athlete.board.title')}
                                        </p>
                                    </NavLink>
                                </li>
                            )}

                            {showHorses && (
                                <li className="nav-item">
                                    <NavLink to={'/horses'} className="nav-link" onClick={this.onItemClicked}>
                                        <i className="nav-icon fas fa-horse-head"/>
                                        <p className="ml-2">
                                            {this.t('horse.board.title')}
                                        </p>
                                    </NavLink>
                                </li>
                            )}

                            {showInstructors && (
                                <li className="nav-item">
                                    <NavLink to={'/instructors'} className="nav-link" onClick={this.onItemClicked}>
                                        <i className="nav-icon fas fa-solid fa-chalkboard-teacher"/>
                                        <p className="ml-2">
                                            {this.t('instructor.board.title')}
                                        </p>
                                    </NavLink>
                                </li>
                            )}

                            {showLessonCards && (
                                <li className="nav-item">
                                    <NavLink to={'/cards'} className="nav-link" onClick={this.onItemClicked}>
                                        <i className="nav-icon fas fa-solid fa-address-card"/>
                                        <p className="ml-2">
                                            {this.t('card.board.title')}
                                        </p>
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
            </aside>

        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    translate: ownProps.t,
    user: state.auth.user,
})

export default withContext(connect(mapStateToProps)(Sidebar))
