const initialState = {
    users: [],
    user: {}
}

const user = (state = initialState, action = {}) => {
    switch(action.type) {
        case 'FIND_USERS':
            return {
                ...state,
                users: action.value,
            }
        case 'FIND_USER':
            return {
                ...state,
                user: action.value,
            }
        case 'ADD_USER':
            return {
                ...state,
                user: action.value,
            }
        case 'UPDATE_USER':
            const user = action.value
            return {
                ...state,
                user: user
            }
        case 'DELETE_USER':
            return {
                ...state,
                users: {
                    content: state.users.content.filter(item => item.id !== parseInt(action.id)),
                    totalElements: state.users.totalElements - 1,
                    size: state.users.size,
                    totalPages: Math.ceil((state.users.totalElements - 1) / state.users.size),
                }
            }
        default: return state
    }
}

export default user;
