import React, {Component} from 'react'
import {connect} from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {updateUser, addUser, findUserById} from "../../actions/user";
import withContext from "../../common/withContext";
import notification from "../../common/notification";
import ContentHeader from "../commons/board-header.component";
import {iconByRole, ROLE, toDateOnly, translationKeyBy} from "../../common/constants";


class UserFormPage extends Component {

    constructor(props) {
        super(props);
        this.t = this.t.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.state = {};
    }

    componentDidMount() {
        if (this.isEdit()) {
            const userId = this.props.id;
            this.props
                .findUserById(userId)
                .then(result => {
                    const user = result.data
                    this.setState({
                        user: {
                            name: user.name,
                            roles: user.roles,
                            email: user.email,
                            surname: user.surname,
                            password: user.password,
                            licenseId: user.licenseId,
                            birthdate: new Date(user.birthdate)
                        }
                    });
                });

        } else {
            // Set default roles according to the user type, e.g ATHLETE/INSTRUCTOR/ADMIN.
            this.setState({
                user: {
                    roles: [this.props.role]
                }
            })
        }
    }

    isEdit() {
        return this.props.id
    }

    handleInputChange(event) {
        this.setState({
            user: {
                ...this.state.user,
                [event.target.name]: event.target.value
            }
        })
    }

    handleDateChange(date) {
        this.setState({
            user: {
                ...this.state.user,
                birthdate: date
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.isEdit()) {
            const userId = this.props.id
            this.props
                .updateUser({
                    ...this.state.user,
                    birthdate: toDateOnly(this.state.user.birthdate)
                }, userId)
                .then(() => {
                    notification.success(this.t('message.update-success'));
                    this.props.navigation(this.props.successRedirect);
                })
                .catch((error) => {
                    notification.error(this.t('message.update-failed'));
                });

        } else {
            this.props
                .addUser({
                    ...this.state.user,
                    birthdate: toDateOnly(this.state.user.birthdate)
                })
                .then(() => {
                    notification.success(this.t('message.add-success'));
                    this.props.navigation(this.props.successRedirect);
                })
                .catch((error) => {
                    notification.error(this.t('message.add-failed'));
                });
        }
    }

    t(key) {
        const role = this.props.role;
        return this.props.translate(`${translationKeyBy(role)}.form.${key}`)
    }

    icon() {
        const role = this.props.role;
        return iconByRole(role);
    }

    render() {
        const {user} = this.state;
        return (
            <React.Fragment>
                <ContentHeader title={this.isEdit() ? this.t('title-edit') : this.t('title-add')}
                               icon={this.icon()}
                               breadcrumbs={[
                                   {label: this.props.parentPageLabel, href: this.props.successRedirect, active: true},
                                   {label: this.isEdit() ? this.t('title-edit') : this.t('title-add'), href: null, active: true}
                               ]}/>

                <section className="content pl-1 pr-1 pl-md-2 pl-lg-2 pl-xl-2 pr-0 pr-md-2 pr-lg-2 pr-xl-2">
                    <div className="container-fluid pl-1 pr-1 pl-md-2 pl-lg-2 pl-xl-2 pr-0 pr-md-2 pr-lg-2 pr-xl-2">
                        <div className="col-lg-6 col-12">

                            <form onSubmit={this.handleSubmit}
                                  className="needs-validation"
                                  autoComplete="off">
                                <div className="form-group">
                                    <label htmlFor="name">
                                        {this.t('input.name')}
                                    </label>
                                    <input id="name"
                                           name="name"
                                           type="text"
                                           value={user?.name}
                                           autoComplete="nope"
                                           className="form-control"
                                           placeholder={this.t('input.name-placeholder')}
                                           aria-describedby="nameHelp"
                                           onChange={this.handleInputChange}
                                           required />
                                    <small id="nameHelp"
                                           className="form-text text-muted">
                                        {this.t('input.name-help')}
                                    </small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="surname">
                                        {this.t('input.surname')}
                                    </label>
                                    <input id="surname"
                                           name="surname"
                                           type="text"
                                           value={user?.surname}
                                           autoComplete="nope"
                                           className="form-control"
                                           placeholder={this.t('input.surname-placeholder')}
                                           aria-describedby="surnameHelp"
                                           onChange={this.handleInputChange}
                                           required />
                                    <small id="surnameHelp"
                                           className="form-text text-muted">
                                        {this.t('input.surname-help')}
                                    </small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="birthdate">
                                        {this.t('input.birthdate')}
                                    </label>
                                    <DatePicker id="birthdate"
                                                name="birthdate"
                                                className="form-control"
                                                placeholderText={this.t('input.birthdate-placeholder')}
                                                selected={user?.birthdate}
                                                dateFormat="dd/MM/yy"
                                                popperModifiers={[{name: 'arrow', options: {padding: 24}}]}
                                                aria-describedby="birthdateHelp"
                                                onChange={this.handleDateChange}
                                                required />
                                    <small id="birthdateHelp"
                                           className="form-text text-muted">
                                        {this.t('input.birthdate-help')}
                                    </small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="licenseId">
                                        {this.t('input.licenseId')}
                                    </label>
                                    <input id="licenseId"
                                           name="licenseId"
                                           type="text"
                                           value={user?.licenseId}
                                           autoComplete="nope"
                                           className="form-control"
                                           placeholder={this.t('input.licenseId-placeholder')}
                                           aria-describedby="licenseIdHelp"
                                           onChange={this.handleInputChange} />
                                    <small id="licenseIdHelp"
                                           className="form-text text-muted">
                                        {this.t('input.licenseId-help')}
                                    </small>
                                </div>

                                {this.props.role === ROLE.INSTRUCTOR || this.props.role === ROLE.ADMIN ?
                                    <div className="form-group">
                                        <label htmlFor="email">
                                            {this.t('input.email')}
                                        </label>
                                        <input id="email"
                                               name="email"
                                               type="email"
                                               value={user?.email}
                                               autoComplete="nope"
                                               className="form-control"
                                               placeholder={this.t('input.email-placeholder')}
                                               aria-describedby="emailHelp"
                                               onChange={this.handleInputChange}
                                               required />
                                        <small id="emailHelp"
                                               className="form-text text-muted">
                                            {this.t('input.email-help')}
                                        </small>
                                    </div>
                                    : ''}


                                {!this.isEdit() &&
                                (this.props.role === ROLE.INSTRUCTOR || this.props.role === ROLE.ADMIN) ?
                                    <div className="form-group">
                                        <label htmlFor="password">
                                            {this.t('input.password')}
                                        </label>
                                        <input id="password"
                                               name="password"
                                               type="password"
                                               autoComplete="new-password"
                                               className="form-control"
                                               placeholder={this.t('input.password-placeholder')}
                                               aria-describedby="passwordHelp"
                                               value={user?.password}
                                               onChange={this.handleInputChange}
                                               required />
                                        <small id="passwordHelp"
                                               className="form-text text-muted">
                                            {this.t('input.password-help')}
                                        </small>
                                    </div>
                                    : ''}

                                <input type="submit"
                                       className="btn btn-primary"
                                       value={this.isEdit() ? this.t('btn.edit') : this.t('btn.add')}/>

                            </form>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state, ownProps) => ({
    id: ownProps.params.id,
    role: ownProps.role,
    translate: ownProps.t,
    navigation: ownProps.navigation,
    successRedirect: ownProps.successRedirect,
    parentPageLabel: ownProps.parentPageLabel
})

const mapDispatchToProps = (dispatch) => ({
    addUser: (data) => dispatch(addUser(data)),
    findUserById: (id) => dispatch(findUserById(id)),
    updateUser: (data, id) => dispatch(updateUser(data, id)),
})

export default withContext(connect(mapStateToProps, mapDispatchToProps)(UserFormPage))
