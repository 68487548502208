import instance from '../services/api';

export const findLessons = (page, size, sorting, dateStart, dateEnd, instructorId, athleteId, horseId, paid) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post('lesson/search', {
                dateStart: dateStart,
                dateEnd: dateEnd,
                instructorId: instructorId,
                athleteId: athleteId,
                horseId: horseId,
                paid: paid
            },
            {
                params: {
                    page: page,
                    size: size,
                    sort: sorting,
                }
            }).then((response) => {
            dispatch({type: 'FIND_LESSONS', value: response.data})
            resolve(response)
        }, (error) => {
            reject(error)
        })
    });
}

export const findLessonById = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.get(`lesson/${id}`)
            .then((response) => {
                dispatch({type: 'FIND_LESSON', value: response.data})
                resolve(response)
            }, (error) => {
                reject(error)
            })
    });
}

export const addLesson = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post('lesson', data)
            .then((response) => {
                dispatch({type: 'ADD_LESSON', value: data})
                resolve(response)
            }, (error) => {
                reject(error)
            })
    });
}

export const updateLesson = (data, id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.put(`lesson/${id}`, data)
            .then((res) => {
                dispatch({type: 'UPDATE_LESSON', value: data, id: id})
                resolve(res)
            }, (err) => {
                reject(err)
            })
    });
}

export const deleteLesson = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.delete(`lesson/${id}`)
            .then((response) => {
                dispatch({type: 'DELETE_LESSON', value: response.data, id: id})
                resolve(response)
            }, (err) => {
                reject(err)
            })
    });
}
