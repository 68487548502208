import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import i18next from "i18next";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import setupInterceptors from "./services/setupInterceptors";
import {I18nextProvider} from "react-i18next";
import language_it from "./translations/it/language.json";

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'it',                              // language to use
    resources: {
        it: {
            language: language_it
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18next}>
            <App />
        </I18nextProvider>
    </Provider>
);

setupInterceptors(store);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
