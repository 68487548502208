import React, { Component } from "react";

import ContentHeader from "../commons/board-header.component";
import Table from "../commons/table.component";
import * as constants from "../../common/constants";
import {connect} from "react-redux";
import DatePicker from "react-datepicker";
import withContext from "../../common/withContext";
import {createReport, findReports, downloadReport} from "../../actions/report";
import {REPORT_STATUS, REPORT_TYPE, toDateOnly} from "../../common/constants";
import notification from "../../common/notification";
import {isMobile} from "react-device-detect";

class BoardReport extends Component {

    constructor(props) {
        super(props);
        this.findReports = this.findReports.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDateEndChange = this.handleDateEndChange.bind(this);
        this.handleDateStartChange = this.handleDateStartChange.bind(this);
        this.state = {
            pageIndex: 0,
            report: {
                inProgress: false
            },
            reports: {
                content: [],
                totalPages: 1,
                totalElements: 0
            }
        };
    }

    findReports(pageRequest) {
        this.props.findReports(
            pageRequest.pageIndex,
            pageRequest.pageSize).then((result) => {
                this.setState({
                    pageIndex: pageRequest.pageIndex,
                    reports: result.data,
                })
        });
    }

    handleDateStartChange(date) {
        this.setState({
            dateStart: date
        })
    }

    handleDateEndChange(date) {
        this.setState({
            dateEnd: date
        })
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            report:  {
                ...this.state.report,
                inProgress: true
            }
        }, () => {

            this.props
                .createReport(
                    REPORT_TYPE.ALL,
                    toDateOnly(this.state.dateStart),
                    toDateOnly(this.state.dateEnd)
                )
                .then(result => {
                    notification.success(this.t('board.message.report-success'));
                })
                .catch(error => {
                    notification.error(this.t('board.message.report-failed'));
                })
                .finally(() => {
                    this.findReports({
                        pageIndex: 0,
                        pageSize: constants.TABLE_DEFAULT_PAGE_SIZE
                    })

                    this.setState({
                        report:  {
                            ...this.state.report,
                            inProgress: false
                        }
                    });
                })
        });
    }

    reportStatusIconOf(status) {
        if (status === REPORT_STATUS.COMPLETED) return 'fa-check text-primary';
        if (status === REPORT_STATUS.PROGRESS) return 'fa-spinner text-secondary'
        if (status === REPORT_STATUS.ERROR) return 'fa-exclamation-triangle text-danger';
        return '';
    }

    t(key) {
        return this.props.translate(`report.${key}`);
    }

    render() {
        const reports = this.state.reports.content;
        const totalPages = this.state.reports.totalPages;
        const totalElements = this.state.reports.totalElements;
        const tablePageIndex = this.state.pageIndex >= totalPages ? totalPages - 1 : this.state.pageIndex;
        const reportInProgress = this.state.report.inProgress;

        const colStatus = {
            Header: this.t('board.table-header.status'),
            Cell: ({row}) => (
                <div style={{textAlign: "center"}}>
                    <i className={`fas fa-solid ${this.reportStatusIconOf(row.original.status)}`}/>
                </div>
            )
        }

        const colCreatedAt = {
            Header: this.t('board.table-header.created-at'),
            Cell: ({row}) => (
                <div style={{textAlign: "center"}}>
                    {constants.formatDateTime(row.original.createdAt)}
                </div>)
        }

        const colName = {
            Header: this.t('board.table-header.name'),
            Cell: ({row}) => (
                <div style={{textAlign: "center"}}>
                    {row.original.name}
                </div>)
        }

        const colDateStart = {
            Header: this.t('board.table-header.date-start'),
            Cell: ({row}) => (
                <div style={{textAlign: "center"}}>
                    {constants.formatDate(row.original.start)}
                </div>)
        }

        const colDateEnd = {
            Header: this.t('board.table-header.date-end'),
            Cell: ({row}) => (
                <div style={{textAlign: "center"}}>
                    {constants.formatDate(row.original.end)}
                </div>)
        }

        const colActions = {
            Header: this.t('board.table-header.actions'),
            Cell: ({row}) => (
                <div style={{textAlign: "center"}}>
                    <button onClick={() => this.props.downloadReport(row.original.uuid)}
                            className={`btn btn-sm btn-primary ${row.original.status !== 'COMPLETED' ? "disabled" : ""}`}>
                        <i className="fas fa-download mr-md-2 mr-lg-2"/>
                        <span className="d-none d-md-inline d-lg-inline">
                            {isMobile ? '' : this.t('board.btn.download')}
                        </span>
                    </button>
                </div>
            )
        }


        const tableColumns = isMobile ?
            [colStatus, colDateStart, colDateEnd, colActions] :
            [colStatus, colCreatedAt, colName, colDateStart, colDateEnd, colActions];
        return (
            <React.Fragment>
                <ContentHeader title={this.t('board.title')}
                               icon="fa-solid fa-file"
                               breadcrumbs={[
                                   { label: this.t('board.title'), href: '/reports', active: true },
                               ]}/>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <p>{this.t('board.subtitle')}</p>
                                <form className="form-inline mt-4"
                                      onSubmit={this.handleSubmit}
                                      autoComplete="off">
                                    <div className="form-row">
                                        <div className="form-group col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <label htmlFor="startDate">{this.t('form.input.date-start')}</label>
                                            <DatePicker id="startDate"
                                                        name="startDate"
                                                        className="form-control"
                                                        selected={this.state.dateStart}
                                                        onChange={this.handleDateStartChange}
                                                        dateFormat="dd/MM/yy"
                                                        autoComplete="off"
                                                        popperModifiers={[{name: 'arrow', options: {padding: 24},},]}
                                                        required />
                                        </div>
                                        <div className="form-group col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <label htmlFor="endDate">{this.t('form.input.date-end')}</label>
                                            <DatePicker id="endDate"
                                                        name="endDate"
                                                        className="form-control"
                                                        selected={this.state.dateEnd}
                                                        onChange={this.handleDateEndChange}
                                                        dateFormat="dd/MM/yy"
                                                        aria-describedby="endDateHelp"
                                                        autoComplete="off"
                                                        popperModifiers={[{name: 'arrow', options: {padding: 24},},]}
                                                        required />
                                        </div>
                                        <div className="form-group col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-2">
                                            {reportInProgress ?
                                                <button type="submit" className="btn btn-primary align-self-end disabled">
                                                    <i className="fas fa-play-circle mr-2"/>
                                                    {this.t('form.btn.generate')}
                                                </button>
                                                :
                                                <button type="submit" className="btn btn-primary align-self-end">
                                                    <i className="fas fa-play-circle mr-2"/>
                                                    {this.t('form.btn.generate')}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-lg-12 col-12">
                                {this.t('board.table-description')}
                                <Table columns={tableColumns}
                                       data={reports}
                                       totalPages={totalPages}
                                       totalElements={totalElements}
                                       tablePageIndex={tablePageIndex}
                                       fetchData={this.findReports}
                                       tablePageSize={constants.TABLE_DEFAULT_PAGE_SIZE}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    translate: ownProps.t,
})

const mapFunctionsToProps = () => ({
    findReports: (page, size) => findReports(page, size),
    createReport: (type, start, end) => createReport(type, start, end),
    downloadReport: (uuid) => downloadReport(uuid)
})

export default withContext(connect(mapStateToProps, mapFunctionsToProps)(BoardReport));

