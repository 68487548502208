import React, {Component} from 'react'
import {connect} from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import withContext from "../../common/withContext";
import ContentHeader from "../commons/board-header.component";
import AsyncSelect from "react-select/async";
import {findUsers} from "../../actions/user";
import {ROLE} from "../../common/constants";
import {addCard} from "../../actions/card";
import notification from "../../common/notification";

class CardFormPage extends Component {

    constructor(props) {
        super(props);
        this.loadAthletesOptions = this.loadAthletesOptions.bind(this);
        this.onHandleAthleteChange = this.onHandleAthleteChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    loadAthletesOptions(inputValue, callback) {
        this.props.findUsers(0, 10, inputValue).then(result => {
            const athletes = result.data.content;
            let athletesOptions = athletes?.map(item => this.asSelectItem(item));
            callback(athletesOptions)
        })
    }

    asSelectItem(item) {
        return {
            value: item.id,
            label: item.name + (item.surname ? ' ' + item.surname : '')
        };
    }

    onHandleAthleteChange(event) {
        this.setState({
            card: {
                athlete: event
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    t(key) {
        return this.props.translate(`card.form.${key}`)
    }

    addCard(size) {
        const {card} = this.state;
        this.props
            .addCard({
                athleteId: card.athlete.value,
                size: size
            })
            .then(() => {
                notification.success(this.t('message.add-success'));
                this.props.navigation(this.props.successRedirect);
            })
            .catch(error => {
                notification.error(this.t('message.add-failed'));
            });
    }

    render() {
        const athletesOptions = [];

        return (
            <React.Fragment>
                <ContentHeader title={this.t('title-add')}
                               icon="fa-solid fa-address-card mr-2"
                               breadcrumbs={[
                               ]}/>

                <section className="content pl-1 pr-1 pl-md-2 pl-lg-2 pl-xl-2 pr-0 pr-md-2 pr-lg-2 pr-xl-2">
                    <div className="container-fluid pl-1 pr-1 pl-md-2 pl-lg-2 pl-xl-2 pr-0 pr-md-2 pr-lg-2 pr-xl-2">
                        <form onSubmit={this.handleSubmit}
                              className="needs-validation"
                              autoComplete="off">
                        <div className="col-lg-6 col-12">
                                <div className="form-group">
                                    <label htmlFor="athlete">
                                        {this.t('input.athlete')}
                                    </label>
                                    <AsyncSelect id="athlete"
                                                 name="athlete"
                                                 searchable="true"
                                                 placeholder={this.t('input.athlete-placeholder')}
                                                 cacheOptions
                                                 defaultOptions={athletesOptions}
                                                 aria-describedby="athleteHelp"
                                                 loadOptions={this.loadAthletesOptions}
                                                 onChange={this.onHandleAthleteChange}
                                                 required />
                                    <small id="athleteHelp"
                                           className="form-text text-muted">
                                        {this.t('input.athlete-help')}
                                    </small>
                                </div>

                        </div>
                        <div className="col-lg-3 col-sm-12 col-md-12 col-xl-3 col-12">
                            <label>
                                Tipo tessera:
                            </label>
                            <button onClick={() => this.addCard(1)}
                                    className={`btn btn-primary mb-2 btn-block`}>
                                <i className="fas fa-plus mr-2"/>
                                <span className="d-md-inline d-lg-inline">
                                       {this.t('btn.add1')}
                                    </span>
                            </button>

                            <br/>

                            <button onClick={() => this.addCard(5)}
                                    className={`btn btn-primary mb-2 btn-block`}>
                                <i className="fas fa-plus mr-2"/>
                                <span className="d-md-inline d-lg-inline">
                                       {this.t('btn.add5')}
                                    </span>
                            </button>
                            <br/>
                            <button onClick={() => this.addCard(10)}
                                    className={`btn btn-primary btn-block`}>
                                <i className="fas fa-plus mr-2"/>
                                <span className="d-md-inline d-lg-inline">
                                       {this.t('btn.add10')}
                                    </span>
                            </button>
                        </div>
                        </form>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    translate: ownProps.t,
    navigation: ownProps.navigation,
    successRedirect: ownProps.successRedirect,
})

const mapDispatchToProps = (dispatch) => ({
    addCard: (data) => dispatch(addCard(data)),
    findUsers: (page, size, searchText) => dispatch(findUsers(page, size, searchText, ROLE.ATHLETE)),
})

export default withContext(connect(mapStateToProps, mapDispatchToProps)(CardFormPage))
