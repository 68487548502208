import React from 'react'
import {useTable, usePagination} from 'react-table'

export default function Table({columns, data, tablePageIndex, tablePageSize, totalElements, totalPages, fetchData}) {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: {pageIndex, pageSize},

    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: tablePageSize
            },
            manualPagination: true,
            pageIndex: tablePageIndex,
            pageCount: totalPages,
        },
        usePagination
    )

    React.useEffect(() => {
        fetchData && fetchData({ pageIndex, pageSize });
    }, [fetchData, pageIndex, pageSize]);

    React.useEffect(() => {
        gotoPage(tablePageIndex);
    }, [tablePageIndex, gotoPage]);


    return (
        <React.Fragment>
            <table {...getTableProps()} className="table mt-3 mb-3">
                <thead className="table-header">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()} style={{verticalAlign: 'middle', textAlign: 'center'}}>
                                {column.render('Header')}
                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
            <div className="float-left mb-3 mt-xl-2">
                <p className="fw-light">Totali: {totalElements}</p>
            </div>
            <div className="float-right mb-3 mt-xl-2">
                <button className="btn btn-primary mr-1" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>
                <button className="btn btn-primary ml-xl-1" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>
                <span className="ml-xl-1 ml-lg-1 ml-md-1 ml-sm-1">
                        Pagina{' '}
                    <strong>
	                    {pageIndex + 1} of {pageOptions.length}
	                </strong>{' '}
	        </span>
                <button className="btn btn-primary mr-1" onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>
                <button className="btn btn-primary ml-xl-1" onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}>
                    {'>>'}
                </button>
            </div>
        </React.Fragment>
    )
}
