const initialState = {
    horses: [],
    horse: {}
}

const horse = (state = initialState, action = {}) => {
    switch(action.type) {
        case 'FIND_HORSES':
            return {
                ...state,
                horses: action.value,
            }
        case 'FIND_HORSE':
            return {
                ...state,
                horse: action.value,
            }
        case 'ADD_HORSE':
            return {
                ...state,
                horse: action.value,
            }
        case 'UPDATE_HORSE':
            const horse = action.value
            return {
                ...state,
                horse: horse
            }
        case 'DELETE_HORSE':
            return {
                ...state,
                horses: {
                    content: state.horses.content.filter(item => item.id !== parseInt(action.id)),
                    totalElements: state.horses.totalElements - 1,
                    size: state.horses.size,
                    totalPages: Math.ceil((state.horses.totalElements - 1) / state.horses.size),
                }
            }
        default: return state
    }
}

export default horse;
