const initialState = {
    cards: [],
    card: {}
}

const card = (state = initialState, action = {}) => {
    switch(action.type) {
        case 'FIND_CARDS':
            return {
                ...state,
                cards: action.value,
            }
        case 'FIND_CARD':
            return {
                ...state,
                card: action.value,
            }
        case 'ADD_CARD':
            return {
                ...state,
                card: action.value,
            }
        case 'DELETE_CARD':
            return {
                ...state,
                cards: {
                    content: state.cards.content.filter(item => item.id !== parseInt(action.id)),
                    totalElements: state.cards.totalElements - 1,
                    size: state.cards.size,
                    totalPages: Math.ceil((state.cards.totalElements - 1) / state.cards.size),
                }
            }
        default: return state
    }
}

export default card;
