import React, {Component} from "react";
import {connect} from "react-redux";
import ContentHeader from "../commons/board-header.component";
import {Link} from "react-router-dom";
import Table from "../commons/table.component";
import * as constants from "../../common/constants";
import {deleteUser, findUsers} from "../../actions/user";
import notification from "../../common/notification";
import {ROLE} from "../../common/constants";
import withContext from "../../common/withContext";
import {isMobile} from "react-device-detect";
import ConfirmBox from "../commons/confirm-box.component";

class BoardAthletes extends Component {

    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.findAthletes = this.findAthletes.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.onHandleSearchClear = this.onHandleSearchClear.bind(this);
        this.onHandleSearchKeyDown = this.onHandleSearchKeyDown.bind(this);
        this.state = {
            pageIndex: 0,
            searchTerm: '',
            isConfirmOpen: false,
            userId: null
        };
    }

    findAthletes(pageRequest) {
        this.props
            .findUsers(
                pageRequest.pageIndex,
                pageRequest.pageSize,
                this.state.searchTerm);
        this.setState({
            pageIndex: pageRequest.pageIndex
        })
    }

    handleDelete(row) {
        this.setState({
            isConfirmOpen: true,
            userId: row.original.id
        })
    }

    handleSearchChange(event) {
        // On typed letter, update the search term.
        this.setState({
            searchTerm: event.target.value
        })
    }

    handleSearch() {
        // For every new search action we go back to the first page.
        this.findAthletes({
            pageIndex: 0,
            pageSize: constants.TABLE_DEFAULT_PAGE_SIZE
        })
    }

    onHandleSearchClear() {
        // On Search Clear pressed, reset the search term and update the data.
        this.setState({
            searchTerm: ''
        }, () => {
            this.findAthletes({
                pageIndex: 0,
                pageSize: constants.TABLE_DEFAULT_PAGE_SIZE
            })
        })
    }

    onHandleSearchKeyDown(event) {
        // On Enter pressed, trigger the search.
        if (event.key === 'Enter') {
            this.handleSearch();
        }
    }

    t(key) {
        return this.props.translate(`athlete.board.${key}`);
    }

    handleClose() {
        this.setState({
            isConfirmOpen: false,
            userId: null
        })
    }

    handleConfirm() {
        this.props
            .deleteUser(this.state.userId)
            .then(result => {
                notification.info(this.t('message.delete-success'));
                this.findAthletes({
                    pageIndex: this.state.pageIndex,
                    pageSize: this.state.pageSize
                })
            })
            .catch(error => {
                notification.error(this.t('message.delete-failed'));
            })
            .finally(() => {
                this.setState({
                    isConfirmOpen: false,
                    userId: null
                })
            })
    }

    handleCancel() {
        this.setState({
            isConfirmOpen: false,
            userId: null
        })
    }

    render() {
        const athletes = this.props.athletes.content ? this.props.athletes.content : [];
        const totalPages = this.props.athletes.totalPages ? this.props.athletes.totalPages : 1;
        const totalElements = this.props.athletes.totalElements ? this.props.athletes.totalElements : 0;

        // If I delete the last element of the last page, I need to go back to the previous page.
        const searchText = this.state.searchTerm
        const isConfirmOpen = this.state.isConfirmOpen;

        const tablePageIndex = this.state.pageIndex >= totalPages ? totalPages - 1 : this.state.pageIndex;

        const colName = {
            Header: this.t('table-header.name'),
            accessor: (props) => (props.name)
        }

        const colSurname = {
            Header: this.t('table-header.surname'),
            accessor: (props) => (props.surname),
        }

        const colBirthdate = {
            Header: this.t('table-header.birthdate'),
            Cell: ({row}) => (
                <div style={{textAlign: "center"}}>
                    {constants.formatDate(row.original.birthdate)}
                </div>)
        }

        const colLicenseId = {
            Header: this.t('table-header.licenseId'),
            Cell: ({row}) => (
                <div style={{textAlign: "center"}}>
                    {row.original.licenseId}
                </div>)
        }

        const colActions = {
            Header: this.t('table-header.actions'),
            Cell: ({row}) => (
                <div style={{textAlign: "center"}}>
                    <Link to={`/athlete/${row.original.id}/edit`}
                          className="btn btn-sm btn-primary mr-lg-2 mr-sm-1 mr-md-1 mr-1">
                        <i className="fas fa-pen mr-md-2 mr-lg-2"/>
                        <span className="d-none d-md-inline d-lg-inline">
                            {isMobile ? '' : this.t('btn.edit')}
                        </span>
                    </Link>
                    <button onClick={() => this.handleDelete(row)}
                            className="btn btn-sm btn-warning ml-lg-2">
                        <i className="fas fa-trash mr-md-2 mr-lg-2"/>
                        <span className="d-none d-md-inline d-lg-inline">
                            {isMobile ? '' : this.t('btn.delete')}
                        </span>
                    </button>
                </div>
            )
        }

        const tableColumns = isMobile ?
            [colName, colSurname, colActions] :
            [colName, colSurname, colBirthdate, colLicenseId, colActions];

        return (
            <React.Fragment>
                <ContentHeader title={this.t('title')}
                               icon="fa-solid fa-user-graduate"
                               breadcrumbs={[
                                   { label: this.t('title'), href: '/athletes', active: false },
                               ]}/>

                <section className="content pl-1 pr-1 pl-md-2 pl-lg-2 pl-xl-2 pr-0 pr-md-2 pr-lg-2 pr-xl-2">
                    <div className="container-fluid pl-1 pr-1 pl-md-2 pl-lg-2 pl-xl-2 pr-0 pr-md-2 pr-lg-2 pr-xl-2">
                        <div className="row mb-4 mt-2">
                            <div className="col-sm-12 col-md-5 col-lg-3 col-xl-3">
                                <Link to={`/athlete/create`}
                                      className="btn btn-primary btn-block mt-2">
                                    <i className={`mr-lg-2 fas fa-plus mr-2`}/>
                                    {this.t('btn.add')}
                                </Link>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                    <div className="input-group mt-2">
                                        <input type="search"
                                               id="search"
                                               value={searchText}
                                               className="form-control"
                                               onChange={this.handleSearchChange}
                                               onKeyDown={this.onHandleSearchKeyDown}/>

                                        <button type="button"
                                                className="btn btn-primary ms-n5"
                                                onClick={this.handleSearch}>
                                            <i className="fas fa-search"/>
                                        </button>

                                        <button type="button"
                                                className="btn btn-primary btn-clear-filter"
                                                onClick={this.onHandleSearchClear}>
                                            Clear
                                        </button>
                                    </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                {this.t('table-description')}
                            <Table columns={tableColumns}
                                   data={athletes}
                                   totalPages={totalPages}
                                   totalElements={totalElements}
                                   tablePageIndex={tablePageIndex}
                                   fetchData={this.findAthletes}
                                   tablePageSize={constants.TABLE_DEFAULT_PAGE_SIZE}
                            />
                            </div>
                        </div>
                    </div>
                    <ConfirmBox
                        options={{
                            icon:"fa-trash",
                            text: this.t('dialog.delete-confirmation'),
                            confirm: this.t('dialog.yes'),
                            cancel: this.t('dialog.no'),
                            btn: true
                        }}
                        isOpen={isConfirmOpen}
                        onClose={this.handleClose}
                        onConfirm={this.handleConfirm}
                        onCancel={this.handleCancel}
                    />
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    translate: ownProps.t,
    athletes: state.user.users
})

const mapDispatchToProps = (dispatch) => ({
    findUsers: (page, size, searchText) => dispatch(findUsers(page, size, searchText, ROLE.ATHLETE)),
    deleteUser: (id) => dispatch(deleteUser(id))
})

export default withContext(connect(mapStateToProps, mapDispatchToProps)(BoardAthletes))
