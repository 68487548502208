const initialState = {
    lessons: [],
    lesson: {}
}

const lesson = (state = initialState, action = {}) => {
    switch(action.type) {
        case 'FIND_LESSONS':
            return {
                ...state,
                lessons: action.value,
            }
        case 'FIND_LESSON':
            return {
                ...state,
                lesson: action.value,
            }
        case 'ADD_LESSON':
            return {
                ...state,
                lesson: action.value,
            }
        case 'UPDATE_LESSON':
            const lesson = action.value
            return {
                ...state,
                lesson: lesson
            }
        case 'DELETE_LESSON':
            return {
                ...state,
                lessons: {
                    content: state.lessons.content.filter(item => item.id !== parseInt(action.id)),
                    totalElements: state.lessons.totalElements - 1,
                    size: state.lessons.size,
                    totalPages: Math.ceil((state.lessons.totalElements - 1) / state.lessons.size),
                }
            }
        default: return state
    }
}

export default lesson;
