import React, {Component} from "react";
import {NavLink} from "react-router-dom";

class ContentHeader extends Component {

    render() {
        const links = this.props.breadcrumbs;
        return (
            <div className="content-header mt-lg-5">
                <div className="container-fluid">
                    <div className="row mt-3">
                        <div className="col-sm-6">
                            <h1 className="m-0"><i className={`mr-lg-3 mr-2 fas ${this.props.icon}`}/>{this.props.title}</h1>
                        </div>
                        <div className="col-sm-6 d-none">
                            <ol className="breadcrumb float-sm-right">
                                {links.map(item => {
                                    return <li key={item.label} className={item.active ? "breadcrumb-item active" : 'breadcrumb-item'}>
                                        {item.href ? <NavLink to={item.href}>{item.label}</NavLink> : item.label}
                                    </li>
                                })}
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContentHeader;
