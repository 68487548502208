import React, {Component} from "react";

import ContentHeader from "../commons/board-header.component";
import {Link} from "react-router-dom";
import withContext from "../../common/withContext";
import {connect} from "react-redux";
import Table from "../commons/table.component";
import notification from "../../common/notification";
import {deleteLesson, findLessons} from "../../actions/lesson";
import * as constants from '../../common/constants';
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async";
import {findUsers} from "../../actions/user";
import {isAdmin, ROLE, toDateEndOnly, toDateOnly} from "../../common/constants";
import {findHorses} from "../../actions/horse";
import Select from 'react-select'
import {isMobile} from 'react-device-detect';
import ConfirmBox from "../commons/confirm-box.component";

class BoardLessons extends Component {

    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.findLessons = this.findLessons.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.onHandlePaidChange = this.onHandlePaidChange.bind(this);
        this.handleDateStartChange = this.handleDateStartChange.bind(this);
        this.handleDateEndChange = this.handleDateEndChange.bind(this);
        this.onHandleInstructorChange = this.onHandleInstructorChange.bind(this);
        this.onHandleAthleteChange = this.onHandleAthleteChange.bind(this);
        this.onHandleHorseChange = this.onHandleHorseChange.bind(this);
        this.loadHorsesOptions = this.loadHorsesOptions.bind(this);
        this.loadAthletesOptions = this.loadAthletesOptions.bind(this);
        this.loadInstructorsOptions = this.loadInstructorsOptions.bind(this);
        this.state = {
            pageIndex: 0,
            isConfirmOpen: false,
            lessonId: null
        };
    }

    findLessons(pageRequest) {
        this.props.findLessons(
            pageRequest.pageIndex,
            pageRequest.pageSize,
            'createdAt,DESC',
            toDateOnly(this.state.dateStart),
            toDateEndOnly(this.state.dateEnd),
            this.state.instructor?.value,
            this.state.athlete?.value,
            this.state.horse?.value,
            this.state.paid?.value);
        this.setState({
            pageIndex: pageRequest.pageIndex
        })
    }

    handleDelete(row) {
        this.setState({
            isConfirmOpen: true,
            lessonId: row.original.id
        })
    }

    handlePaidChange(id, event) {
        event.target.setAttribute('disabled', '');
        const checked = event.target.checked;
        if (checked) {
            this.props
                .payLesson(id)
                .then(result => {
                    notification.success(this.t('message.pay-success'));
                    this.findLessons({
                        pageIndex: this.state.pageIndex,
                        pageSize: this.state.pageSize
                    })
                })
                .catch(error => {
                    notification.error(this.t('message.pay-failed'));
                })
                .finally(() => {
                    event.target.removeAttribute('disabled');
                })
        } else {
            this.props
                .unPayLesson(id)
                .then(result => {
                    notification.info(this.t('message.unPay-success'));
                    this.findLessons({
                        pageIndex: this.state.pageIndex,
                        pageSize: this.state.pageSize
                    })
                })
                .catch(error => {
                    notification.error(this.t('message.unPay-failed'));
                })
                .finally(() => {
                    event.target.removeAttribute('disabled');
                })
        }
    }

    t(key) {
        return this.props.translate(`lesson.board.${key}`);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.findLessons({
                pageIndex: 0,
                pageSize: 0
            })
    }

    handleDateStartChange(date) {
        this.setState({
            dateStart: date
        })
    }

    handleDateEndChange(date) {
        this.setState({
            dateEnd: date
        })
    }

    onHandlePaidChange(event) {
        this.setState({
            paid: event
        });
    }

    onHandleInstructorChange(event) {
        this.setState({
            instructor: event
        });
    }

    onHandleAthleteChange(event) {
        this.setState({
            athlete: event
        });
    }

    onHandleHorseChange(event) {
        this.setState({
            horse: event
        });
    }

    loadInstructorsOptions(inputValue, callback) {
        this.props
            .findInstructors(0, 10, inputValue)
            .then(result => {
                const instructors = result.data.content;
                let instructorsOptions = instructors?.map(item => this.asSelectItem(item));
                callback(instructorsOptions)
            })
    }

    loadAthletesOptions(inputValue, callback) {
        this.props
            .findAthletes(0, 10, inputValue)
            .then(result => {
                const athletes = result.data.content;
                let athletesOptions = athletes?.map(item => this.asSelectItem(item));
                callback(athletesOptions)
            })
    }

    loadHorsesOptions(inputValue, callback) {
        this.props.findHorses(0, 10, inputValue).then(result => {
            const horses = result.data.content;
            const horsesOptions = horses?.map(item => this.asSelectItem(item));
            callback(horsesOptions)
        })
    }

    asSelectItem(item) {
        return {
            value: item.id,
            label: item.name + (item.surname ? ' ' + item.surname : '')
        };
    }

    clearFilters() {
        this.setState({
            dateEnd: null,
            dateStart: null,
            horse: null,
            athlete: null,
            instructor: null,
            paid: null
        })
    }

    handleClose() {
        this.setState({
            isConfirmOpen: false,
            lessonId: null
        })
    }

    handleConfirm() {
        this.props
            .deleteLesson(this.state.lessonId)
            .then(result => {
                notification.info(this.t('message.delete-success'));
                this.findLessons({
                    pageIndex: this.state.pageIndex,
                    pageSize: this.state.pageSize
                })
            })
            .catch(error => {
                notification.error(this.t('message.delete-failed'));
            })
            .finally(() => {
                this.setState({
                    isConfirmOpen: false,
                    lessonId: null
                })
            })
    }

    handleCancel() {
        this.setState({
            isConfirmOpen: false,
            lessonId: null
        })
    }

    render() {
        const lessons = this.props.lessons.content ? this.props.lessons.content : [];
        const totalPages = this.props.lessons.totalPages ? this.props.lessons.totalPages : 1;
        const totalElements = this.props.lessons.totalElements ? this.props.lessons.totalElements : 0;
        const tablePageIndex = this.state.pageIndex >= totalPages ? totalPages - 1 : this.state.pageIndex;
        const user = this.props.user;
        const {instructor, athlete, horse, paid, isConfirmOpen} = this.state;

        const paidOptions = [
            { value: true, label: 'Pagata' },
            { value: false, label: 'Non pagata' },
        ]

        const colAthlete = {
            Header: this.t('table-header.athlete'),
            accessor: (props) => (props.athlete.surname + ' ' + props.athlete.name)
        };

        const colInstructor = {
            Header: this.t('table-header.instructor'),
            accessor: (props) => (props.instructor.surname + ' ' + props.instructor.name),
        }

        const colHorse = {
            Header: this.t('table-header.horse'),
            accessor: (props) => (props.horse.name),
        }

        const colDateTime = {
            Header: this.t('table-header.datetime'),
            Cell: ({row}) => (
                <div style={{ textAlign: "center" }}>
                    {constants.formatDateTime(row.original.date)}
                </div>)
        }

        const colPaid = {
            Header: this.t('table-header.paid'),
            Cell: ({row}) => (
                <div style={{ textAlign: "center" }}>
                    {row.original.paid ? <i className="text-primary fas fa-check-circle"/> : ''}
                </div>)
        }

        const colActions = {
            Header: this.t('table-header.actions'),
            Cell: ({row}) => (
                <div style={{ textAlign: "center" }}>
                    <Link to={`/lesson/${row.original.id}/edit`}
                          className="btn btn-sm btn-primary mr-lg-2 mr-sm-1 mr-md-1 mr-1">
                        <i className="fas fa-pen mr-md-2 mr-lg-2"/>
                        <span className="d-none d-md-inline d-lg-inline">
                            {isMobile ? '' : this.t('btn.edit')}
                        </span>
                    </Link>
                    <button onClick={() => this.handleDelete(row)}
                            className="btn btn-sm btn-warning ml-lg-2">
                        <i className="fas fa-trash mr-md-2 mr-lg-2"/>
                        <span className="d-none d-md-inline d-lg-inline">
                            {isMobile ? '' : this.t('btn.delete')}
                        </span>
                    </button>
                </div>
            )
        }

        const tableColumns = isMobile ?
            [colAthlete, colDateTime, colActions] :
            [colAthlete, colInstructor, colHorse, colDateTime, colPaid, colActions];

        return (
            <React.Fragment>
                <ContentHeader title={this.t('title')}
                               icon="fa-solid fa-calendar"
                               breadcrumbs={[
                                   { label: this.t('title'), href: '/lessons', active: true },
                               ]}/>

                <section className="content pl-1 pr-1 pl-md-2 pl-lg-2 pl-xl-2 pr-0 pr-md-2 pr-lg-2 pr-xl-2">
                    <div className="container-fluid pl-1 pr-1 pl-md-2 pl-lg-2 pl-xl-2 pr-0 pr-md-2 pr-lg-2 pr-xl-2">
                        <div className="row mb-4 mt-2">
                            <div className="col-sm-12 col-md-5 col-lg-3 col-xl-3">
                                <Link to={`/lesson/create`}
                                      className="btn btn-primary btn-block mt-2">
                                    <i className={`mr-lg-2 fas fa-plus mr-2`}/>
                                    {this.t('btn.add')}
                                </Link>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-3 col-xl-3">
                                <a data-toggle="collapse"
                                   className="btn btn-primary btn-block mt-2"
                                   href="#lessonsFilters"
                                   role="button"
                                   aria-expanded="false"
                                   aria-controls="lessonsFilters">
                                    <i className="fas fa-chevron-right mr-2"/>
                                    {this.t('btn.filter')}
                                </a>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <div className="collapse" id="lessonsFilters">
                                    <div className="card card-body">
                                        <form onSubmit={this.handleSubmit}
                                              autoComplete="off">

                                            <div className="form-row">
                                                <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                    <label htmlFor="startDate">
                                                        {this.t('form.input.date-start')}
                                                    </label>
                                                    <DatePicker id="startDate"
                                                                name="startDate"
                                                                className="form-control"
                                                                selected={this.state.dateStart}
                                                                onChange={this.handleDateStartChange}
                                                                placeholderText={this.t('form.input.date-start-placeholder')}
                                                                dateFormat="dd/MM/yy"
                                                                autoComplete="off"
                                                                popperModifiers={[{
                                                                    name: 'arrow',
                                                                    options: {padding: 24},
                                                                },]}/>
                                                </div>

                                                <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                    <label htmlFor="endDate">
                                                        {this.t('form.input.date-end')}
                                                    </label>
                                                    <DatePicker id="endDate"
                                                                name="endDate"
                                                                className="form-control"
                                                                selected={this.state.dateEnd}
                                                                onChange={this.handleDateEndChange}
                                                                placeholderText={this.t('form.input.date-end-placeholder')}
                                                                dateFormat="dd/MM/yy"
                                                                autoComplete="off"
                                                                popperModifiers={[{
                                                                    name: 'arrow',
                                                                    options: {padding: 24},
                                                                },]}/>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                    <label htmlFor="athlete">
                                                        {this.t('form.input.athlete')}
                                                    </label>
                                                    <AsyncSelect id="athlete"
                                                                 name="athlete"
                                                                 searchable="true"
                                                                 placeholder={this.t('form.input.athlete-placeholder')}
                                                                 cacheOptions
                                                                 value={athlete}
                                                                 loadOptions={this.loadAthletesOptions}
                                                                 onChange={this.onHandleAthleteChange}/>
                                                </div>


                                                <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                    <label htmlFor="horse">
                                                        {this.t('form.input.horse')}
                                                    </label>
                                                    <AsyncSelect id="horse"
                                                                 name="horse"
                                                                 searchable="true"
                                                                 placeholder={this.t('form.input.horse-placeholder')}
                                                                 cacheOptions
                                                                 value={horse}
                                                                 loadOptions={this.loadHorsesOptions}
                                                                 onChange={this.onHandleHorseChange}/>
                                                </div>
                                            </div>

                                            {isAdmin(user) ?
                                                <div className="form-row">
                                                    <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                        <label htmlFor="instructor">
                                                            {this.t('form.input.instructor')}
                                                        </label>
                                                        <AsyncSelect id="instructor"
                                                                     name="instructor"
                                                                     searchable="true"
                                                                     placeholder={this.t('form.input.instructor-placeholder')}
                                                                     cacheOptions
                                                                     value={instructor}
                                                                     loadOptions={this.loadInstructorsOptions}
                                                                     onChange={this.onHandleInstructorChange}/>
                                                    </div>

                                                    <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                        <label htmlFor="paid">
                                                            {this.t('form.input.paid')}
                                                        </label>
                                                        <Select id="paid"
                                                                value={paid}
                                                                options={paidOptions}
                                                                placeholder={this.t('form.input.paid-placeholder')}
                                                                onChange={this.onHandlePaidChange}/>
                                                    </div>
                                                </div>
                                                : ''}

                                            <div className="form-row">
                                                <div className="form-group col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-4 col-12">
                                                    <button type="submit"
                                                            className="btn btn-primary btn-block">
                                                        {this.t('form.btn.find')}
                                                    </button>
                                                </div>
                                                <div className="form-group col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-4 col-12">
                                                    <button type="submit"
                                                            className="btn btn-primary btn-block"
                                                            onClick={this.clearFilters}>
                                                        {this.t('form.btn.find-clear')}
                                                    </button>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 col-12">
                                {this.t('table-description')}
                                <Table columns={tableColumns}
                                       data={lessons}
                                       totalPages={totalPages}
                                       totalElements={totalElements}
                                       tablePageIndex={tablePageIndex}
                                       fetchData={this.findLessons}
                                       tablePageSize={constants.TABLE_DEFAULT_PAGE_SIZE}
                                />
                            </div>
                        </div>
                    </div>
                    <ConfirmBox
                        options={{
                            icon:"fa-trash",
                            text: 'Eliminare definitivamente la lezione?',
                            confirm: 'Si',
                            cancel: 'No',
                            btn: true
                        }}
                        isOpen={isConfirmOpen}
                        onClose={this.handleClose}
                        onConfirm={this.handleConfirm}
                        onCancel={this.handleCancel}
                    />
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    translate: ownProps.t,
    lessons: state.lesson.lessons,
    user: state.auth.user,
})

const mapDispatchToProps = (dispatch) => ({
    findAthletes: (page, size, searchText) => dispatch(findUsers(page, size, searchText, ROLE.ATHLETE)),
    findInstructors: (page, size, searchText) => dispatch(findUsers(page, size, searchText, ROLE.INSTRUCTOR)),
    findLessons: (page, size, sorting, dateStart, dateEnd, instructorId, athleteId, horseId, paid) => dispatch(findLessons(page, size, sorting, dateStart, dateEnd, instructorId, athleteId, horseId, paid)),
    deleteLesson: (id) => dispatch(deleteLesson(id)),
    findHorses: (page, size, name) => dispatch(findHorses(page, size, name))
})

export default withContext(connect(mapStateToProps, mapDispatchToProps)(BoardLessons))
