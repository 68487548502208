import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";

const withContext = WrappedComponent => props => {
    const {t} = useTranslation('language');
    const params = useParams();
    const navigation = useNavigate();
    return (
        <WrappedComponent
            {...props}
            t={t}
            params={params}
            navigation={navigation}
        />
    );
};

export default withContext;
